import React from "react"
import Layout from "../components/layout"
import Meta from "../components/meta"
import Hero from "../components/hero"
import Image from "../components/image"

export default () => (
  <Layout>
    <Meta title="メンテナンス" />
    <Hero title="メンテナンス" />
    <div className="text-center text-gray-800 mb-8">
      <h3 className="text-xl font-bold mb-1">車検・一般整備</h3>
      <p className="max-w-2xl mx-auto text-gray-800 mb-3 text-sm px-5 text-left">
        故障診断は、経験と知識及び人間の五感で判断するアナログ式、専用のコンピューターテスター（Das・Xentry）を使用してメモリー値・実測値等から判断するデジタル式などそれぞれの年代の車両に応じた判断をします。
        また定期的な点検を行うことによって消耗品パーツの摩耗具合や次回交換時期の判定、日常点検では判りにくい下廻り足廻り等の点検をすることにより愛車のより良いコンディションを保つことができます。
      </p>
      <div className="block md:flex mx-auto justify-center">
        <div className="pr-2 mb-4 text-center">
          <Image filename="sk1.png" alt="" className="w-48 mx-auto" />
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="sk2.png" alt="" className="w-48 mx-auto" />
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="sk3.png" alt="" className="w-48 mx-auto" />
        </div>
        <div className="text-center mb-4">
          <Image filename="sk4.png" alt="" className="w-48 mx-auto" />
        </div>
      </div>
    </div>
    <div className="text-center text-gray-800 mb-8">
      <h3 className="text-xl font-bold mb-1">車検・一般整備</h3>
      <p className="max-w-2xl mx-auto text-gray-800 mb-3 text-sm px-5 text-left">
        レストアは、部分的レストア・フルレストア等、修理範囲がとても広い作業です。
        基本的には新車当時の状態に復元する事を目指し、外装・内装・機関・足廻り等細部に渡り手直しをします。
        当然作業内容により金額にも幅がありますがご要望に応じた対応が可能です。
        例えば中古車を購入し新車の雰囲気にするには内装のレストアをお勧めします　身体に触れる箇所を新品にする事によってオーナー固有の感触を得ることができます。
        機関のレストアは弊社が最も得意とする分野です　自信をもってご満足頂けますようご対応いたします。
        思わぬアクシデントや事故などによって着いてしまったキズや凹み・再塗装など　保険作業も含め対応いたします。
      </p>
      <div className="block md:flex mx-auto justify-center">
        <div className="pr-2 mb-4 text-center">
          <Image filename="bk1.png" alt="" className="w-48 mx-auto" />
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="bk2.png" alt="" className="w-48 mx-auto" />
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="bk3.png" alt="" className="w-48 mx-auto" />
        </div>
        <div className="text-center mb-4">
          <Image filename="bk4.png" alt="" className="w-48 mx-auto" />
        </div>
      </div>
    </div>
  </Layout>
)
